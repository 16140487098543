import { Box, Button, Card, CardMedia, Typography } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { ApiLoadingGuard } from './ApiLoadingGuard';
import { ApiCallState } from '../api';
import { ImageCardError } from './ImageCardError';

interface DownloadableImageCardProps {
  height?: string;
  image: ApiCallState<string>;
  showDownloadButton: boolean;
  downloadFileName: string;
}

interface NonDownloadableImageCardProps {
  height?: string;
  image: ApiCallState<string>;
  showDownloadButton?: never;
  downloadFileName?: never;
}

export type ImageCardProps = DownloadableImageCardProps | NonDownloadableImageCardProps;

export function ImageCard(props: ImageCardProps) {
  return (
    <Card
      sx={{
        borderRadius: '10px',
        padding: '1rem',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        height: props.height ? props.height : 'calc(100% - 2rem)',
      }}
      elevation={8}
    >
      <Typography variant='h6' alignSelf={'flex-start'}>
        Ladefläche
      </Typography>
      <ApiLoadingGuard apiStates={[props.image]} error_display={ImageCardError}>
        <Box
          sx={{
            position: 'relative',
            overflow: 'hidden',
            height: '100%',
            margin: '1%',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
            }}
          >
            <CardMedia
              component='img'
              src={props.image.result}
              sx={{
                borderRadius: '10px',
                objectFit: 'contain',
                // Because there are images of very different sizes this needs a hard value
                // otherwise some images will overflow the parent card component.
                maxHeight: '30vh',
              }}
            />
          </Box>
        </Box>

        {props.showDownloadButton && (
          <Button
            variant='contained'
            href={`data:application/octet-stream;base64,${props.image.result?.slice(22)}`}
            download={props.downloadFileName}
            startIcon={<FileDownloadIcon />}
          >
            Download
          </Button>
        )}
      </ApiLoadingGuard>
    </Card>
  );
}
