import { Close, ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import { Box, AppBar, Toolbar, IconButton, Typography } from '@mui/material';
import { DETAILVIEW_TOOLBAR_HEIGHT } from '../../Constants';

export interface DetailViewToolbarProps {
  onCloseButtonPressed(): void;
  onNextButtonPressed(): void;
  onPreviousButtonPressed(): void;
  nextButtonEnabled: boolean;
  previousButtonEnabled: boolean;
  toolbarTitle?: string;
}

export default function DetailViewToolbar(props: DetailViewToolbarProps) {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position='static'
        sx={{
          height: `${DETAILVIEW_TOOLBAR_HEIGHT}rem`,
          minHeight: `${DETAILVIEW_TOOLBAR_HEIGHT}rem`,
        }}
      >
        {/* Set minHeight: "100%", because MuiToolbar-root otherwise overwrites the height of the toolbar */}
        <Toolbar
          sx={{
            '@media (min-width: 600px)': {
              minHeight: '100%',
            },
            width: '100%',
            backgroundColor: 'white',
          }}
        >
          <IconButton
            size='large'
            edge='start'
            color='primary'
            aria-label='closeOverlay'
            sx={{ mr: 2 }}
            onClick={props.onCloseButtonPressed}
          >
            <Close />
          </IconButton>
          <Typography variant='h6' color='black' sx={{ flexGrow: 1 }}>
            {props.toolbarTitle ? `Wiegescheinnummer: ${props.toolbarTitle}` : 'Details'}
          </Typography>
          <IconButton
            disabled={!props.previousButtonEnabled}
            size='large'
            edge='start'
            color='primary'
            aria-label='previousOverlayItem'
            sx={{ mr: '1rem' }}
            onClick={props.onPreviousButtonPressed}
          >
            <ArrowBackIos />
          </IconButton>
          <IconButton
            disabled={!props.nextButtonEnabled}
            size='large'
            edge='start'
            color='primary'
            aria-label='nextOverlayItem'
            sx={{ mr: '2rem' }}
            onClick={props.onNextButtonPressed}
          >
            <ArrowForwardIos />
          </IconButton>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
