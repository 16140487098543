import React, { createContext, PropsWithChildren } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useSpinner } from './SpinnerContext';
import {
  JobsiteDTO,
  JobsiteService,
  OpenAPI,
  OrganisationService,
  UserPopulatedDTO,
  UserService,
} from './api/generated';
import { buildQueryParams } from './api';

export interface UserContextProps {
  user?: UserPopulatedDTO;
  reloadUser: () => void;
}

export const UserContext = createContext<UserContextProps>({
  reloadUser: () => {},
});

export function UserProvider(props: PropsWithChildren) {
  const { setLoading } = useSpinner();
  const { getAccessTokenSilently, user: auth0user } = useAuth0();
  const [user, setUser] = React.useState<UserPopulatedDTO>();
  const [reloadTrigger, setReloadTrigger] = React.useState<number>(0);

  async function getToken(): Promise<string> {
    return await getAccessTokenSilently();
  }

  OpenAPI.TOKEN = getToken;

  function reloadUser() {
    setReloadTrigger((prev) => prev + 1);
  }

  React.useEffect(() => {
    (async (): Promise<UserPopulatedDTO> => {
      setLoading(true);
      setUser(undefined);
      if (!auth0user) return Promise.reject(new Error('No user'));
      await UserService.userControllerLoginUser();
      const baseUser = await UserService.userControllerGetUser({ authId: auth0user?.sub || '' });
      const organisation = await OrganisationService.organisationControllerGetOrganisation({
        organisationId: baseUser.organisationId,
      });

      let jobsites: JobsiteDTO[] = [];
      if (baseUser.jobsiteIds.length > 0) {
        jobsites =
          (
            await JobsiteService.jobsiteControllerGetJobsites({
              organisationId: baseUser.organisationId,
              query: buildQueryParams({
                filter: {
                  filterFields: baseUser.jobsiteIds.map((id) => ({
                    filterField: 'id',
                    operatorValue: 'equals',
                    value: id,
                  })),
                  filterLinkOperator: 'or',
                } as any,
              }),
            })
          )?.data || [];
      }

      const preferredJobsite = jobsites.find((j) => j.id === baseUser.preferredJobsiteId);

      return {
        ...baseUser,
        organisation,
        jobsites: jobsites,
        preferredJobsite,
      };
    })()
      .then((result) => {
        setUser(result);
      })
      .catch((err) => {
        console.error({ error: `${err.message}` });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [reloadTrigger, auth0user]);

  return <UserContext.Provider value={{ user, reloadUser }}>{props.children}</UserContext.Provider>;
}
