import { Config } from './typing';

export const config: Config = {
  basename: '/',
  muiLicenseKey: '357961255f667f2df1cbb65ba5d13726Tz04MjE1NixFPTE3MzY3NjEzMjcwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=',
  auth0: {
    domain: 'dev-8evjnobwwjbehjd0.us.auth0.com',
    clientId: 'SM3ve4Z6IFYNxWdXWu6w83nL2fuU3Ux8',
    audience: 'https://desk.optocycle.com/api',
  },
};
